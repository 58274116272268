<template>
  <div v-if="services.length > 0" class="prefooter">
    <div v-for="(service, index) in services" :key="`service-${index}`">
      <nuxt-link
        class="prefooter__service"
        v-if="service.link"
        :to="localePath(service.link)"
      >
        <nuxt-img
          class="prefooter__service__icon"
          :src="service.icon"
          :alt="service.label"
        />
        <div class="prefooter__service__label">
          {{ service.label }}
        </div>
      </nuxt-link>
      <div class="prefooter__service" v-else>
        <nuxt-img
          class="prefooter__service__icon"
          :src="service.icon"
          :alt="service.label"
        />
        <div class="prefooter__service__label">
          {{ service.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, defineComponent } from '@nuxtjs/composition-api';
import { useCms } from '~/composables';

export default defineComponent({
  name: 'Prefooter',
  props: {
    blockData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { getComponent } = useCms();

    const services = ref([]);

    onMounted(async () => {
      const prefooterData = await getComponent('prefooter');
      services.value =
        prefooterData?.services
          ?.map((s) => ({
            icon: s?.icon?.url || '',
            label: s?.label || '',
            link: s?.link || '',
          }))
          ?.filter((s) => s.icon && s.label) || [];
    });

    return {
      services,
    };
  },
});
</script>

<style lang="scss" scoped>
.prefooter {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 0.625rem;
  margin: 7.5rem 0 0;
  padding-bottom: 7.5rem;
  @include desktop-boxed-inner;
  &__service {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.625rem;
    padding: 1.875rem 0;
    border: 0.0625rem solid var(--c-primary-lightest);
    &__icon {
      height: 2.5rem;
    }
    &__label {
      font-family: var(--font-family-albert-sans);
      line-height: var(--line-spacing-25);
      @include text-16-regular-black;
    }
  }
  @include to-tablet-max {
    grid-template-columns: 1fr;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}
</style>
